import { formatDate, NgIf, NgStyle, NgClass, NgFor, JsonPipe, CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfMakeService } from 'app/core/services/pdf-make.service';
import { wkHtmlToPdfService } from 'app/core/services/wkHtmlToPdf.service';
import moment from 'moment';
import { map, Observable, of, Subscription } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { EntityService } from '../../services/entity.service';
import { DashboardDatacontrolComponent } from '../dashboard-datacontrol/dashboard-datacontrol.component';
import { DashboardOptionsComponent } from '../dashboard-options/dashboard-options.component';
import { DxiColumnModule, DxoSelectionModule } from 'devextreme-angular/ui/nested';
import { DxTreeListModule } from 'devextreme-angular/ui/tree-list';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxTemplateModule } from 'devextreme-angular/core';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { NumbersOnlyDirective } from '../../directives/numbers-only.directive';
import { FormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { DropDownOption, ToolbarDropdownNavYearComponent } from '../toolbar/custom-menus/dropdown-nav-year/toolbar-dropdown-nav-year.component';
import { CurrencyDropdownMenuComponent } from '../toolbar/custom-menus/currency-dropdown-menu/currency-dropdown-menu.component';


@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, NgClass, CommonModule, ToolbarDropdownNavYearComponent, CurrencyDropdownMenuComponent, DashboardDatacontrolComponent, NgbTooltip, NgFor, ProgressbarModule, DashboardOptionsComponent, FormsModule, NumbersOnlyDirective, DxDropDownBoxModule, DxTemplateModule, DxListModule, DxTreeListModule, DxoSelectionModule, DxiColumnModule, JsonPipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild('optionsDropdown') optionsDropdown: DashboardOptionsComponent;
    @ViewChild('datacontrolDropdown') datacontrolDropdown: DashboardDatacontrolComponent;
    /**
     * Set if this dashboard it's gonna use a datePicker
     * (true by default)
     */
    @Input() public datePicker = true;
    // @Input() public dataControls = true;
    /**
     * Set if this dashboard cannot be agreagated (mutiple hotel selection)
     * (false by default)
     */
    @Output() public change: EventEmitter<any> = new EventEmitter();
    /**
     * On some dashboards it's needed to have options and selectedOptions to filter the query on the server side but it's also
     * required to this be hidden on the view (usually, this options will work as default filters).
     */
    @Input() public showOptions = true;
    @Input() public updateOrma = false;
    @Input() public showDataControls = false;
    @Input() public showSlide = true;
    @Input() public footer = true;
    @Input() public header = true;
    @Input() public rightClickMenuEnabled = false;
    /**
     * True adds margin to top of header-title (e.g. for sales production spacing)
     */
    @Input() public headerTitleMargin = false;
    /**
     * If true, top right options menus become fixed in upper-right on scroll
     */
    @Input() public stickyMenus = false;
    public isInSlide = false;
    /**
     * If true, display the entityService.selectedEntityLabel near dashboard title
     */
    @Input() public entityLabel = true;
    @Input() public indexArray: any[] = [];
    @Input() public scrollIndex: (any, number) => void;
    @Input() public getPrintData: () => void;
    @Input() public getHtmlData: () => void;
    @Input() public downloadData: () => void;

    hideOptionMenu:boolean = false;
    
    protected onChange: any = Function.prototype;
    protected onTouched: any = Function.prototype;
    public hiddenOptionsContent: boolean;
    options: any[];
    multiTreeDisableUpdateButton = false;
    multiDisableUpdateButton = false;
    checkboxDisableUpdateButton = false;
    public dateInput = {
        start: moment().subtract(1, 'month').startOf('month'),
        end: moment().subtract(1, 'month').endOf('month'),
        freq: 'MTD'
    };
    public hiddenMenuItems: number[];
    public dropdownOutsideCloseDisabled: boolean;
    public progressData = [];
    private subscriptions: Subscription[] = [];
    constructor(
        public entityService: EntityService,
        public dashboardService: DashboardService,
        private pdfMakeService: PdfMakeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private wkhtmltopdfservice: wkHtmlToPdfService
    ) {
        this.hiddenMenuItems = [];
    }

    public ngOnInit(): void {
        if (this.datacontrolDropdown) {
            this.datacontrolDropdown.show = false; // Force it
        }
        this.subscriptions.push(
            this.dashboardService.on_init.subscribe((data) => {
                if (data) {
                    this.setOptions();
                    this._subscribeToRouteFragment();
                    this.dashboardService.selectedEntities =
                        this.entityService.selectedEntityIds;
                    this.dashboardService.selectedHtlId =
                        this.entityService.selectedHtlId;
                }
            })
        );

        this.dashboardService.dashboardOptionMenuVisibility$.subscribe(isVisible=>{
        
            this.hideOptionMenu = isVisible;
        })

        
    }

    public setOptions(reset?: boolean): void {
        if (reset) {
            this.hiddenOptionsContent = true;
        }
        this.options = this.dashboardService.options;
        const localStorageCurrency = +localStorage.getItem('currency');
        if (
            this.options.findIndex((el) => el.name === 'currency') !== -1 &&
            typeof localStorageCurrency === 'number'
        ) {
            this.options.find((el) => el.name === 'currency').selected =
                localStorageCurrency;
        }
        const localStorageMktGroup = localStorage.getItem('mkt_group');
        if (this.options.findIndex((el) => el.name === 'mkt_group') !== -1) {
            if (localStorageMktGroup) {
                this.options.find((el) => el.name === 'mkt_group').selected =
                    localStorageMktGroup;
            } /* else {
                this.options.find((el) => el.name === 'mkt_group').selected = '';
            } */
        }
        setTimeout(() => {
            this.hiddenOptionsContent = false;
            if (this.options && this.options.length) {
                setTimeout(() => {
                    const elt: any = $('.selectpicker');
                    elt.selectpicker({});
                }, 100);
            }
        }, 100);
        this.updateOptions(true);
    }
    /**
     * Update the selectedOptions of the dashboardService.
     * Trigger if not specified the update callBack
     * @param avoidCallBack
     */
    public updateOptions(avoidCallBack?: boolean): void {
        const params: any = {};
        if (this.options) {
            for (const elt of this.options) {
                params[elt.name] = elt.selected;
            }
        }
        if (this.datePicker) {
            params.dateInput = this.dateInput;
        }
        if (avoidCallBack) {
            this.dashboardService.selectedOptions = params;
            // const storedCurrency: number = +localStorage.getItem('currency');
            // if (typeof storedCurrency === 'number') {
            //     this.dashboardService.selectedOptions.currency = storedCurrency;
            // }
        } else {
            this.dashboardService.updateOptions(params);
        }
        if (this.optionsDropdown) {
            this.optionsDropdown.show = false; // Force it
        }
    }
    onCheckboxOptionChnaged(optionIdx: number, id: any): void {
        const options = this.options[optionIdx];
        if (options.selected.includes(id)) {
            options.selected = options.selected.filter((el) => el !== id);
        } else {
            options.selected.push(id);
        }
        this.options[optionIdx].mandatory && options.selected.length == 0
            ? (this.checkboxDisableUpdateButton = true)
            : (this.checkboxDisableUpdateButton = false);
    }
    onSelectionChanged(e, i): void {
        const selectedItems = e.component.option('selectedItems');
        this.options[i].selected = [];
        for (const item of selectedItems) {
            this.options[i].selected.push(item.id);
        }
        // Handler of the 'selectionChanged' event
        this.options[i].mandatory && selectedItems.length == 0
            ? (this.multiDisableUpdateButton = true)
            : (this.multiDisableUpdateButton = false);
        this.onTouched();
        this.onChange(this.options[i]);
        this.change.next(this.options[i]);
    }
    onSelectionChangedTree(e, i): void {
        const selectedItems = e.component.getSelectedRowsData('leavesOnly');
        this.options[i].selected = [];
        for (const item of selectedItems) {
            this.options[i].selected.push(item.id);
        }
        this.options[i].mandatory && selectedItems.length == 0
            ? (this.multiTreeDisableUpdateButton = true)
            : (this.multiTreeDisableUpdateButton = false);
    }
    public selectedDate(value: any, dateInput: any): void {
        dateInput.start = value.start;
        dateInput.end = value.end;
        switch (value.label) {
            case 'Month to Date':
                dateInput.frequency = 'MTD';
                break;
            case 'Year to Date':
                dateInput.frequency = 'YTD';
                break;
            case 'Full Year':
                dateInput.frequency = 'FYR';
                break;
            default:
                dateInput.frequency = 'MTD';
        }
        this.updateOptions();
    }
    public download(): void {
        // method not used for the moment
    }
    public print(): void {
        this.pdfMakeService.downloadDashboardPdf(this.getPrintData.bind(this));
    }
    public uploadOrma(): void {
        this.dashboardService.createWPMatrice();
    }
    public sendToWkHtmlToPdf(): void {
        this.wkhtmltopdfservice.createDashboardPdf(this.getHtmlData.bind(this));
    }

    public changeSliderPageView(): void {
        this.isInSlide = !this.isInSlide;
        this.dashboardService.setSlideWidgetView(this.isInSlide);
        this.dashboardService.changeSliderPageView();
    }
    public registerOnChange(fn: (_: any) => any): void {
        this.onChange = fn;
    }
    public registerOnTouched(fn: () => any): void {
        this.onTouched = fn;
    }
    public formatMyDate(date: string, format: string, locale: string): string {
        return formatDate(date, format, locale);
    }
    public updateDropdownOutsideCloseTrigger(disabled: boolean): void {
        this.dropdownOutsideCloseDisabled = disabled;
    }
    public routeToFragment(node: any): void {
        this.router.navigateByUrl(
            '/dashboard/' + this.dashboardService.id + '#' + node.id,
            { replaceUrl: true }
        );
    }
    public onMenuRightClick(node: any): boolean {
        if (this.rightClickMenuEnabled) {
            const elementToHideShow = document.getElementById(node.id);
            if (elementToHideShow) {
                if (elementToHideShow.style.display !== 'none') {
                    elementToHideShow.style.display = 'none';
                    this.hiddenMenuItems.push(node.idx);
                } else {
                    elementToHideShow.style.display = 'block';
                    this.hiddenMenuItems = this.hiddenMenuItems.filter(
                        (el) => el !== node.idx
                    );
                }
            }
        }
        return false;
    }
    public updateProgressData(event: any): void {
        this.progressData = event;
    }
    private _subscribeToRouteFragment(): void {
        this.subscriptions.push(
            this.activatedRoute.fragment.subscribe((fragment: string) => {
                if (fragment) {
                    if (this.indexArray) {
                        //console.log('indexArray', this.indexArray);
                        const _node = this.indexArray.filter(
                            (node) => node.id === fragment
                        )[0];
                        if (_node) {
                            this.scrollIndex(_node, -300);
                        }
                    }
                }
            })
        );
    }
    public ngOnDestroy(): void {
        
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        this.datePicker = null;
        this.change = null;
        this.showOptions = null;
        this.showDataControls = null;
        this.showSlide = true;
        this.entityLabel = null;
        this.indexArray = null;
        this.scrollIndex = null;
        this.hiddenMenuItems = [];
        this.stickyMenus = false;
        this.rightClickMenuEnabled = false;
        this.isInSlide = false;

        this.dashboardService.updateDashboardOptionMenuVisibility(false);
    }

  
    
    /* getYearOptions(): DropDownOption[] {
        return [{ id: 2023, label: '2023' }, { id: 2024, label: '2024' }, { id: 2025, label: '2025' }]
    } */
    trackByFn(index: number, option: any): number {
        return option.id;
    }

    yearOptions$ = of([
        { id: 2023, label: '2023' },
        { id: 2024, label: '2024' },
        { id: 2025, label: '2025' },
    ]);
    getYearOptions(): Observable<DropDownOption[]> {
        return this.yearOptions$;
    }

    getSelectedYear(): Observable<DropDownOption> {
        return this.dashboardService.dashboardSelectedYear$.pipe(map(year => ({ id: year.id, label: year.label })));
        }
    selectYear(value: any): void {
        if (!value) return;
        this.dashboardService.updateDashboardSelectedYear(value)
    }

    currencyOptions$ = of([
        { id: 0, label: 'EUR' }
    ]);
    getCurrencyOptions(): Observable<DropDownOption[]> {
        let currencies:any=[]
        if (this.dashboardService.options){
            currencies = this.dashboardService.options.find(
                option => option.name === 'currency' && option.type === 'SELECT'
            )?.options || []; // Fallback to empty array if no options are found
        }
        
        return this.currencyOptions$.pipe(
            map(staticOptions => [...currencies]) // Combine static and dynamic options
        );
    }

    getSelectedCurrency(): Observable<DropDownOption> {
        return this.dashboardService.dashboardSelectedCurrency$.pipe(map(curr => ({ id: curr.id, label: curr.label })));
    }
    selectCurrency(value: any): void {
        if (!value) return;
        this.dashboardService.updateDashboardSelectedCurrency(value)
    }
}
